import React from 'react'
import { Link } from 'react-router-dom'

import styles from './CompareRates.module.scss'

const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'IllinoisIndiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

const CompareRates = () => {
  return (
    <div className={`${styles.comparerates} container`}>
      <h2 className='section-title'>Compare rates in your state</h2>
      <div className='wrap'>
        <div className='table'>
          {states.map((state, index) => (
            <div className='table-item'>
              <Link to='/full-form.html' key={index}>
                {state}
              </Link>
            </div>
          ))}
        </div>
        <div className='image'>
          <img src='/images/home/state-map.svg' alt='state map' width='563' />
        </div>
      </div>
    </div>
  )
}

export default CompareRates
