import React from 'react'
import { useDispatch } from 'react-redux'
import { Form, Field } from 'react-final-form'

import FinalFormInputMask from 'components/forms/FinalFormInputMaskNew'
import ArrowButton from 'components/ArrowButton'
import { zipcode } from 'components/forms/validators'
import Spinner from 'components/Spinner'

import { ReactComponent as TimeRegular } from 'icons/timer-regular.svg'

import { getZipcode } from 'store/zipcode/actions'
import { trackFormEvent } from 'api/gtm'

const ZipCodeStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit: nextStep,
  stepNumber,
}) => {
  const dispatch = useDispatch<any>()

  const onSubmit = (formData: any) => {
    trackFormEvent(ZipCodeStep.displayName, formData.zipcode, stepNumber, 2)

    dispatch(getZipcode({ zipcode: formData.zipcode }))
    nextStep({ zipcode: formData.zipcode })
  }

  const onNextClick = (errors: any, formData: any) => {
    // track event if form is invalid
    if (Object.keys(errors).length > 0) {
      trackFormEvent(
        ZipCodeStep.displayName,
        formData.zipcode || '',
        stepNumber,
        2,
        'next',
        'error'
      )
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, errors, values }) => (
        <form
          className='form-step step-zipcode'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <div className='box'>
            <h4 className='title'>
              Enter your zip code to get personalized health quotes
            </h4>
            <h5>
              <TimeRegular />
              It takes less than 2 minutes to review your savings!
            </h5>
            <div className='content'>
              <Field
                type='tel'
                name='zipcode'
                defaultValue={info.zipcode}
                validate={zipcode}
                className={'zipcode'}
                placeholder='Zip code'
                label={'Zipcode'}
              >
                {(props) => (
                  <FinalFormInputMask
                    mask='99999'
                    maskPlaceholder=''
                    {...props}
                  />
                )}
              </Field>

              <ArrowButton
                type='submit'
                className='btn btn-blue'
                disabled={submitting}
                onClick={() => onNextClick(errors, values)}
              >
                Get My Quotes
              </ArrowButton>
            </div>
          </div>
          {submitting && <Spinner />}
        </form>
      )}
    />
  )
}
ZipCodeStep.displayName = 'What is your zip code?'

export default ZipCodeStep
