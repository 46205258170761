import React, { useState } from 'react'

import styles from './FAQItem.module.scss'

type Props = {
  data: {
    title: string
    contentOne: string
    contentTwo?: string
  }
}

const FAQItem: React.FC<Props> = ({ data }) => {
  const [opened, setOpened] = useState<boolean>(false)

  return (
    <div className={`${styles.faqitem} ${opened ? 'active' : ''}`}>
      <div className='faq-title' onClick={() => setOpened(!opened)}>
        {data.title}
        <div className='faq-icon'></div>
      </div>
      <div className='faq-content'>
        <p>{data.contentOne}</p>
        <p>{data.contentTwo}</p>
      </div>
    </div>
  )
}

export default FAQItem
