import React from 'react'

import FAQItem from '../components/FAQItem'

import styles from './FAQ.module.scss'

const faqs = [
  {
    title: 'How do I find the right health insurance?',
    contentOne: `Each person has their own health and financial situation,
therefore the right plan for any person is dependent on a few
factors. First, how much can you afford to pay per month for
health insurance? Then, how low do you want your out of pocket
expenses? Our licensed insurance agnets will help you search
for balance of cost and out-of-pocket expenses for your case.`,
  },
  {
    title: 'What is a deductible?',
    contentOne: `Insurance providers set a minimum amount that you must spend
  out of pocket prior to your insurance kicking in. The
  deductible amount typically is a total over a 12 month period.
  For example, if you’re deductible is $2500, then after you’ve
  accumulated over $2500 in medical bills during a year period,
  then you’re insurance will kick in and start to cover the
  remaining costs.`,
    contentTwo: `Healthier people tend to choose higher deductible plans. But,
  keep in mind, that you must be financially able to pay this
  deductible amount in the event of an emergency.`,
  },
  {
    title: 'How do I buy health insurance outside of open enrollment?',
    contentOne: `If you meet a certain life condition, such as moving to a new
  state or getting a new job, then you can enroll in a new
  healthcare plan at any time.`,
    contentTwo: `If you don’t have a valid reason for purchasing healthcare
  outside of open enrollment, then you may purchase a short term
  plan that can last up to 12 months.`,
  },
  {
    title: 'What is the difference between an HMO and a PPO?',
    contentOne: `An HMO plan limits you to only doctors within your network,
  and matches you to a primary care doctor. Prior to any new
  medical procedures or appointments, you will need to visit
  your primary care doctor for a referral for it to be covered
  by your plan.`,
    contentTwo: `A PPO plan allows you to access a much wider range of doctors,
  and gives you the flexibility to go visit any of those doctors
  without a referral. This plan offers the most flexibility, but
  also costs significantly more.`,
  },
  {
    title: 'Can I qualify for a subsidized plan?',
    contentOne: `Subsidized plans are handled on a case by case basis. Each
  applicant will be analyzed based on their income and other
  criteria to see if they should be eligible to receive
  subsidized health insurance.`,
    contentTwo: `Typically, a candidate’s income must only be slightly above
  the U.S. poverty level in order to qualify. This amount ranges
  depending on the number of people in your household. For a 1
  person household, the limit is approximately $16,642 in yearly
  income, and for a 4 person it is $33,948.`,
  },
  {
    title: 'Why buy a plan if my employer offers health insurance?',
    contentOne: `In most cases, employers offer a limited number of options for
  healthcare, which would most likely limit you to several
  options from just one carrier. An employer may pay some or all
  of these costs for you, but keep in mind that you will still
  be taxed on the payments as income, and in many cases an
  employer would be willing to pay you directly your health
  insurance benefits versus enrolling you in one of their plans.`,
    contentTwo: `If you choose to enroll in your own plan, you will have much
  more flexibility when it comes to which plan you choose. Much
  of the time, people can find a much more suitable plan at a
  more affordable price when choosing a plan on their own.`,
  },
  {
    title: 'What is the right health insurance provider?',
    contentOne: `The right health insurance provider is a personal preference,
  those factors that help someone chose their plan and provider.
  For some it is the cheapest premium cost, while for others is
  it the least out-of-pocket costs when getting health services.`,
    contentTwo: `Quick Health may help you find the right provider and plan for
  your case.`,
  },
  {
    title: 'Can I get a plan with no deductible?',
    contentOne: `The lowest deductible is usually $500 for the most premium
  health plans. In some states there are $0 deductible plans,
  but they can be very expense, and may outweigh the added costs
  involved.`,
    contentTwo: `If you speak to our licensed insurance agents, we can help
  look at your health situation and help calculate the right
  plan that will cost you the least amount of money out of
  pocket over time.`,
  },
]

const FAQ = () => {
  return (
    <div className={styles.faq}>
      <div className='container'>
        <h2 className='section-title'>
          Common Questions About Health Insurance
        </h2>
        <div className='faq-wrap'>
          {faqs.map((item, index) => (
            <FAQItem data={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default FAQ
