import React from 'react'

import styles from './InsuranceType.module.scss'

const InsuranceType = () => {
  return (
    <div className={styles.insurancetype}>
      <div className='container'>
        <h2 className='section-title'>
          What type of insurance are you looking for?
        </h2>
        <div className='insurance-types'>
          <div className='insurance-types-item'>
            <div className='image'>
              <img
                src='/images/home/types/short-term-insurance.svg'
                alt='short term insurance'
                width='162'
              />
            </div>
            <h4>Short Term Insurance</h4>
            <p>
              Short term insurance is ideal for those who missed their deadline
              for open enrollment and need a temporary solution.
            </p>
            <a href='/full-form.html'>
              <span>Continue</span>{' '}
              <svg width='19' height='22' viewBox='0 0 448 512'>
                <use href='/images/fa-pro/light.svg#long-arrow-right'></use>
              </svg>
            </a>
          </div>
          <div className='insurance-types-item'>
            <div className='image'>
              <img
                src='/images/home/types/health-insurance.svg'
                alt='health insurance'
                width='210'
              />
            </div>
            <h4>Health Insurance</h4>
            <p>
              Everyone can enroll in a plan during open enrollment. Those with
              approved life situations can also enroll in a health plan outside
              open enrollment. There are also options for any case with short
              term plans.
            </p>
            <a href='/full-form.html'>
              <span>Continue</span>{' '}
              <svg width='19' height='22' viewBox='0 0 448 512'>
                <use href='/images/fa-pro/light.svg#long-arrow-right'></use>
              </svg>
            </a>
          </div>
          <div className='insurance-types-item'>
            <div className='image'>
              <img
                src='/images/home/types/business-insurance.svg'
                alt='business insurance'
                width='183'
              />
            </div>
            <h4>Business Insurance</h4>
            <p>
              Those who also own a business can purcahse a plan for themselves
              and their employees.
            </p>
            <a href='/full-form.html'>
              <span>Continue</span>{' '}
              <svg width='19' height='22' viewBox='0 0 448 512'>
                <use href='/images/fa-pro/light.svg#long-arrow-right'></use>
              </svg>
            </a>
          </div>
          <div className='insurance-types-item'>
            <div className='image'>
              <img
                src='/images/home/types/obamacare-plans.svg'
                alt='obamacare plans'
                width='222'
              />
            </div>
            <h4>Obamacare Plans</h4>
            <p>
              Enroll in a subsidized plan during open enrollment, or outside of
              open enrollment for those who meet certain life situations.
            </p>
            <a href='/full-form.html'>
              <span>Continue</span>{' '}
              <svg width='19' height='22' viewBox='0 0 448 512'>
                <use href='/images/fa-pro/light.svg#long-arrow-right'></use>
              </svg>
            </a>
          </div>
          <div className='insurance-types-item'>
            <div className='image'>
              <img
                src='/images/home/types/medicare-supplement.svg'
                alt='medicare supplement'
                width='183'
              />
            </div>
            <h4>Medicare Supplement</h4>
            <p>
              Those on Medicare can purchased a supplemental plan to help cover
              out-of-pocket costs.
            </p>
            <a href='/full-form.html'>
              <span>Continue</span>{' '}
              <svg width='19' height='22' viewBox='0 0 448 512'>
                <use href='/images/fa-pro/light.svg#long-arrow-right'></use>
              </svg>
            </a>
          </div>
          <div className='insurance-types-item'>
            <div className='image'>
              <img
                src='/images/home/types/medicare-advantage.svg'
                alt='medicare advantage'
                width='155'
              />
            </div>
            <h4>Medicare Advantage</h4>
            <p>
              Medicare recipients can replace their government plans with a
              private Medicare Advantage plan. Plans are available starting at
              $0.
            </p>
            <a href='/full-form.html'>
              <span>Continue</span>{' '}
              <svg width='19' height='22' viewBox='0 0 448 512'>
                <use href='/images/fa-pro/light.svg#long-arrow-right'></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsuranceType
