//Google Tag Manager
import { states } from 'data/usStates'

export function initialize() {
  const gtmId = process.env.REACT_APP_GTMID
  const gtmUrl = `https://${process.env.REACT_APP_GTMDOMAIN}`
  let nScript: any = document.getElementById('tag-noscript')

  nScript.innerHTML = `<iframe src="${gtmUrl}/ns.html?id=${gtmId}"
  height="0" width="0" style="display: none; visibility: hidden" ></iframe >`

    ; (function (wnd, doc, script, layer, id) {
      wnd[layer] = wnd[layer] || []
      wnd[layer].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var originalTag = doc.getElementsByTagName(script)[0],
        el: HTMLScriptElement = doc.createElement(script) as HTMLScriptElement,
        dl = layer !== 'dataLayer' ? '&l=' + layer : ''
      el.async = true
      el.src = gtmUrl + '/gtm.js?id=' + id + dl
      originalTag.parentNode.insertBefore(el, originalTag)
    })(window, document, 'script', 'dataLayer', gtmId)
}
//Google Tag Manaer

export const getStateCode = (state: string) => {
  for (let key in states) {
    if (states[key].toUpperCase() === state.toUpperCase()) {
      return key
    }
    if (key.toUpperCase() === state.toUpperCase()) {
      return key
    }
  }
  return undefined
}


export const getStringfromObject = (values: Object) => {
  let step_value,
    arr = []
  Object.keys(values).forEach((key) => {
    arr.push(`${key}:${values[key]}`)
  })
  step_value = arr.join(';')

  return step_value
}

export const getRealPhoneNumber = (phoneNumber: string) => {
  phoneNumber = phoneNumber.replace(/[^0-9]/g, '')
  return phoneNumber
}