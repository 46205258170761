import React from 'react'
import './FinalFormRadio.scss'

type Props = {
  component?: 'input'
  input: any
  meta: any
  label?: string
  id?: string
  val?: any
  selected?: any
  handleChange?: any
}
const FinalFormRadio: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  component = 'input',
  input,
  meta,
  label,
  id,
  val,
  selected,
  handleChange,
  ...props
}) => {
  const inputElem = React.createElement(component, {
    ...input,
    ...props,
    value: val,
    checked: selected === val,
    onChange: handleChange,
  })
  return (
    <label
      className={`checkcontainer ${selected === val ? 'checked' : 'unchecked'}`}
    >
      <span className='info-label'>{label}</span>
      {inputElem}
      <span className='radiobtn-around'></span>
      <span className='radiobtn'></span>
    </label>
  )
}

export default FinalFormRadio
