import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import cookie from 'react-cookies'

import { trackFormEvent } from 'api/gtm'

import steps from './steps'
import { initialize, getStateCode, getRealPhoneNumber } from 'utils/helper'
import { getRequestData } from 'utils/leadData'
import { insertLeadScripts, insertTrustFormScripts } from 'utils/scripts'

type Props = {
  geoInfo?: any
  zipData: any
}

type MyState = {
  info: any
  currentStep: number
}
class Form5 extends React.Component<Props, MyState> {
  defaultInfo = {
    gender: 'Male',
  }

  actualSteps: any[]

  constructor(props: Props) {
    super(props)

    const { geoInfo } = props

    this.state = {
      info: {
        ...this.defaultInfo,
        ...geoInfo,
      },
      currentStep: geoInfo && geoInfo.queryZip ? 1 : 0,
    }

    this.actualSteps = [...steps]
  }

  componentWillReceiveProps(props: Props) {
    const { zipData } = props

    const updatedInfo = {
      ...this.state.info,
      ...zipData,
    }
    let updatedState = {
      info: updatedInfo,
    }

    this.setState(updatedState)
  }

  async componentDidMount() {
    window.history.pushState(
      null,
      '',
      window.location.pathname + window.location.search
    )
    window.addEventListener('popstate', this.onBackButtonEvent)

    window['dataLayer'] = [
      {
        step_question: this.actualSteps[this.state.currentStep].displayName,
        step_number: this.state.currentStep + 1,
        geo_source: this.state.info.queryZip ? 'URL' : 'Maxmind',
        geo_region: this.state.info.state,
        geo_zip: this.state.info.zipcode,
        page_type: 'multi step form',
        form_version: 2,
      },
    ]
    initialize()

    insertLeadScripts()
    insertTrustFormScripts()
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onBackButtonEvent)
  }

  onBackButtonEvent = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.currentStep === 0) {
      if (
        window.confirm('Are you sure to back? You will lost your information.')
      ) {
        window.history.back()
      }
    } else {
      this.goToPrevious()
      window.history.pushState(
        null,
        '',
        window.location.pathname + window.location.search
      )
    }
  }

  goToPrevious = () => {
    const updatedInfo = {
      ...this.state,
      currentStep: this.state.currentStep - 1,
    }

    this.setState(updatedInfo, () => {
      window.scrollTo(0, 0)
    })

    trackFormEvent(
      this.actualSteps[this.state.currentStep].displayName,
      '',
      this.state.currentStep + 1,
      2,
      'back',
      'pass'
    )
  }

  goToNext = async (form: any, stepCount: number = 1) => {
    // if (infoData.flowStatus !== undefined && infoData.flowStatus === 'Click') {
    //   localStorage.setItem('param', JSON.stringify(this.state.info))
    //   const param = window.location.search
    //   window.location.replace(`/personalized-quote/confirmation.html${param}`)
    //   return
    // }

    const updatedInfo = {
      ...this.state.info,
      ...form,
    }
    let updatedState = {
      info: updatedInfo,
      currentStep: this.state.currentStep + stepCount,
    }

    // submit lead form if address step

    if (this.state.currentStep === this.actualSteps.length - 1) {
      const leadData = {
        ...updatedState.info,
        vehicleList: undefined,
        medicareType: 'supplement',
      }

      // modify slightly

      // leadData.lp_campaign_key = 'df6x2rYBD3McQz9TnbJH'
      // leadData.lp_campaign_id = '5f4fd40d7c82d'

      leadData.zipcode = leadData.zipcode || '78703'
      leadData.state = leadData.state || 'Texas'
      leadData.stateCode = leadData.stateCode || 'Texas'
      leadData.city = leadData.city || 'Austin'
      leadData.address = leadData.address || '123 No Address Provided'
      leadData.phone = getRealPhoneNumber(leadData.phone)
      leadData.state = getStateCode(leadData.state) || leadData.state
      leadData.birthYear = leadData.year

      // delete unnecessary fields

      delete leadData.medicareType
      delete leadData.prescription
      delete leadData.vehicleList

      const requestBody = getRequestData(leadData)

      cookie.save('form-leaddata', requestBody, { path: '/' })

      if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        if (leadData.income === '0-45000' && leadData.medicare_part === 'no') {
          await axios.post(
            'https://penguin.oapi.me/api/lead/8/submit-v2',
            requestBody
          )
        } else if (
          leadData.income !== '0-45000' &&
          leadData.medicare_part === 'no'
        ) {
          await axios.post(
            'https://penguin.oapi.me/api/lead/8/submit-v2',
            requestBody
          )
        } else {
          await axios.post(
            'https://penguin.oapi.me/api/lead/1/submit-v2',
            requestBody
          )
        }
      } else {
        console.log(requestBody)
      }
      localStorage.setItem('param', JSON.stringify(requestBody.lead))
      const param = window.location.search
      if (leadData.medicare_part === 'no') {
        window.location.replace(`/confirmation.html${param}`)
      } else {
        window.location.replace(`/personalized-quote/confirmation.html${param}`)
      }
    }

    // check if the final step is submitted
    if (this.state.currentStep < this.actualSteps.length - 1) {
      this.setState(updatedState, () => {
        window.scrollTo(0, 0)
      })
    }
  }

  render() {
    const StepComponent = this.actualSteps[this.state.currentStep]

    return (
      <>
        <header>
          <div className='container'>
            <img src={`/images/${process.env.REACT_APP_LOGO_URL}`} alt='logo' />
            <div className='contact-agent'>
              <span>Want to speak with an agent?</span>
              <span>(844) 348-2454</span>
              <span>Mon-Sun, 7am - 10pm CST</span>
            </div>
          </div>
        </header>

        <main className='form-container'>
          <StepComponent
            info={this.state.info}
            onSubmit={this.goToNext}
            onPrevious={this.goToPrevious}
            stepNumber={this.state.currentStep + 1}
          />
        </main>

        <input
          type='hidden'
          id='leadid_tcpa_disclosure'
          checked={true}
          readOnly={true}
        />
        <input type='hidden' name='universal_leadid' id='leadid_token' />

        <input type='hidden' id='leadid_tcpa_disclosure' />
      </>
    )
  }
}

const mapStateToProps = (store: any) => ({
  zipData: store.project.zipData,
})

export default connect(mapStateToProps)(Form5)
