import axios from 'axios'
import getStateName from 'data/usStates'
import { createTypes } from 'redux-action-creator'
export const types = createTypes(['GET_ZIPCODE_DATA'])

export const getZipcode = (values: any) => (dispatch: any) =>
  new Promise((resolve, reject) => {
    axios
      .get('https://penguin.oapi.me/api/services/check-zip-us', {
        params: { zip: values.zipcode },
      })
      .then((res) => {
        const { data } = res
        if (data.valid) {
          data.zipcode = data.zip
          delete data.zip

          dispatch({
            type: types.GET_ZIPCODE_DATA,
            payload: {
              ...data,
              state: getStateName(data.state),
              stateCode: data.state,
            },
          })
        } else {
          throw new Error('Not found')
        }
      })
      .catch(() => {
        dispatch({
          type: types.GET_ZIPCODE_DATA,
          payload: {
            zipcode: values.zipcode,
            city: undefined,
            state: undefined,
            stateCode: undefined,
          },
        })
      })
  })
