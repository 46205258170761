import React, { useState } from 'react'

import styles from './Zipcode.module.scss'

const Zipcode = () => {
  const [zip, setZip] = useState<string>('')

  const handleChange = (e) => {
    setZip(e.target.value)
  }

  const handleClick = () => {
    const isVlaid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)
    if (!isVlaid) return
    window.location.href = `full-form.html?zip=${zip}`
  }

  return (
    <div className={styles.zipcode}>
      <div className='control'>
        <input
          type='tel'
          maxLength={5}
          placeholder='Zip Code'
          autoComplete='off'
          value={zip}
          onChange={handleChange}
        />
      </div>
      <button className='btn' onClick={handleClick}>
        <span className='flex-wrap'>
          <span>Start</span>
          <svg width='32' height='37' viewBox='0 0 448 512'>
            <use href='/images/fa-pro/light.svg#long-arrow-right'></use>
          </svg>
        </span>
      </button>
    </div>
  )
}

export default Zipcode
