import axios from 'axios'
import queryString from 'query-string'

export function getClicks(leadData: any, maxResults: number = 5, type: number = 1): Promise<any> {
  const queryParams = queryString.parse(window.location.search)

  let curUrl = document.location.href
  if (curUrl.indexOf('?') > 0) {
    curUrl = curUrl.substr(0, curUrl.indexOf('?'))
  }
  
  let token = '0ce30349-d383-4e9d-8185-b3cf288b889f'

  if (type === 2) {
    token = '8278f557-4a3f-4241-ab03-b88d84add854'
  }
  if (type === 3) {
    token = '380aa9f7-67ce-4bc6-b94a-052011fccd8d'
  }
  
  return axios.post(`https://penguin.oapi.me/api/click/get-links`, {
    token: token,
    maxResults,
    t1: queryParams.t1,
    t2: queryParams.t2 || leadData.cpc_placement_id,
    t3: queryParams.t3,
    t4: queryParams.t4,
    t5: queryParams.t5,
    lead: leadData,
    adInfo: {
      voluum_click_id: queryParams.clickid,
    },
    submissionUrl: curUrl,
  })
}

export function getVehicleTrim(year: string | number, make: string, model: string) {
  return axios.get('https://penguin.oapi.me/api/vehicle/vehicle-trim', {
    params: {
      year,
      make: make.toUpperCase(),
      model: model.toUpperCase(),
    }
  })
}