import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { initialize } from 'utils/helper'

import Home from 'pages/Home'
import PersonalizedQuote from 'pages/PersonalizedQuote'
import Confirmation from 'pages/Confirmation'

class App extends React.Component {
  componentWillMount() {
    document.documentElement.style.setProperty(
      '--theme-color',
      process.env.REACT_APP_THEME_PRIMARY
    )
  }
  componentDidMount() {
    this.appHeight()

    window.addEventListener('resize', this.appHeight)
    initialize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.appHeight)
  }

  appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  render() {
    return (
      <Switch>
        <Route path='/' exact={true}>
          <Home />
        </Route>

        <Route path='/personalized-quote.html'>
          <PersonalizedQuote />
        </Route>

        <Route path='/personalized-quote/confirmation.html'>
          <Confirmation />
        </Route>

        {/* <Route
          path='/redirect'
          render={() => window.location.replace(' https://quickhealthcare.com')}
        />
        <Redirect to='/redirect' /> */}
      </Switch>
    )
  }
}

export default App
