import React from 'react'
import Footer from './Footer'
import queryString from 'query-string'
import axios from 'axios'
import getStateName from 'data/usStates'
import Spinner from 'components/Spinner'

type Props = {
  title?: string
  className?: string
  children: React.ReactNode
}

class MainLayout extends React.Component<Props> {
  state: any = {
    geoInfo: {},
    loading: true,
  }

  async componentDidMount() {
    const queryParams = queryString.parse(window.location.search)
    let newState = this.state

    // check query string if zip exists ex: https://honestquote.com/form?zipcode=78701
    try {
      if (queryParams.zipcode) {
        const { data: zipInfo } = await axios.get(
          'https://penguin.oapi.me/api/services/check-zip-us',
          {
            params: { zip: queryParams.zipcode },
          }
        )
        newState = {
          loading: false,
          geoInfo: {
            ...zipInfo,
            state: getStateName(zipInfo.state),
            stateCode: zipInfo.state,
            queryZip: true,
          },
        }
      } else {
        let getUrl
        if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
          getUrl =
            document.location.protocol + '//' + process.env.REACT_APP_DOMAIN
        } else {
          getUrl = 'https://honestquote.com'
        }

        const { headers: locationInfo } = await axios.get(getUrl)

        if (
          locationInfo['x-optimize-geo-conn-type'] === 'wifi' ||
          locationInfo['x-optimize-geo-conn-type'] === 'wired'
        ) {
          newState = {
            loading: false,
            geoInfo: {
              state: getStateName(locationInfo['x-optimize-geo-region']),
              zip: locationInfo['x-optimize-geo-zipcode'],
            },
          }
        } else {
          newState = {
            loading: false,
          }
        }
      }

      newState.geoInfo = {
        ...newState.geoInfo,
        flow: queryParams.flow ? queryParams.flow : '1',
      }
    } catch {
      newState = { loading: false }
    }

    this.setState(newState)

    if (this.props.className) {
      document.body.classList.add(this.props.className)
    }
  }

  getChildren = () => {
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { geoInfo: this.state.geoInfo })
        }
        return child
      }
    )

    return childrenWithProps
  }

  render() {
    return this.state.loading ? (
      <Spinner />
    ) : (
      <>
        {this.getChildren()}
        <Footer />
      </>
    )
  }
}
export default MainLayout
