import React, { useState, useEffect } from 'react'

import { ReactComponent as RightArrow } from 'icons/arrow-right-white.svg'

import styles from './Popup.module.scss'

type Props = {
  status?: any
}

const Popup: React.FC<Props> = ({ status }) => {

  const [shown, setShown] = useState<string>('popup-hide')

  useEffect(() => {
    if (status === 'show') {
      setShown('popup-show')
    }
  }, [status]) //eslint-disable-line

  const handleClick = () => {
    setShown('popup-hide')
  }
  return (
    <div className={`${styles.popup} ${shown}`}>
      <div className="background" onClick={handleClick}/>
      <div className='content'>
        <p>
          In order to provide accurate quotes we are connecting you to a
          licensed health coverage professional in your area. <br /><br/>
          Please answer the phone when they call in the next 5 minutes.
        </p>
        <button onClick={handleClick}>Continue To Online Quotes<RightArrow /></button>
      </div>
    </div>
  )
}

export default Popup
