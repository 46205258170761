import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className='footer-content container'>
        <Link to='/' className='logo'>
          Quick Healthcare
        </Link>
        <div className='footer-menu-wrap'>
          <div className='footer-menu'>
            <h4>Products</h4>
            <Link to='/full-form.html'>Health Insurance</Link>
            <Link to='/full-form.html'>Short Term Insurance</Link>
            <Link to='/full-form.html'>Small Business</Link>
            <Link to='/full-form.html'>Obamacare Plans</Link>
            <Link to='/full-form.html'>Medicare Supplement</Link>
            <Link to='/full-form.html'>Medicare Advantage</Link>
          </div>
          <div className='footer-menu'>
            <h4>Healthcare</h4>
            <Link to='/full-form.html'>By Plan Type</Link>
            <Link to='/full-form.html'>By State</Link>
            <Link to='/full-form.html'>By Insurance Company</Link>
          </div>
          <div className='footer-menu'>
            <h4>Resources</h4>
            <Link to='/full-form.html'>Health Insurance</Link>
            <Link to='/full-form.html'>Obamacare</Link>
            <Link to='/full-form.html'>Medicare Coverage</Link>
            <Link to='/full-form.html'>Plan Comparison</Link>
            <Link to='/full-form.html'>Newsletter</Link>
          </div>
          <div className='footer-menu'>
            <h4>Company</h4>
            <Link to='/about-us.html'>About Us</Link>
            <Link to='/partner-form.html'>Become a Partner</Link>
            <Link to='/terms-of-use.html'>Terms of Use</Link>
            <Link to='/privacy-policy.html'>Privacy Policy</Link>
            <Link to='/contact-us.html'>Contact Us</Link>
          </div>
        </div>
      </div>
      <div className='container copyright'>
        © 2021 Quick Health Inc. All rights reserved
        <br />
        Non-government affiliated
      </div>
    </footer>
  )
}

export default Footer
