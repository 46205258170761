import React from 'react'
import { Form, Field } from 'react-final-form'
import FinalFormRadio from 'components/forms/FinalFormRadio'
import { trackFormEvent } from 'api/gtm'

const properties = [
  { label: 'Male', value: 'M' },
  { label: 'Female', value: 'F' },
  { label: 'Non-binary', value: 'N' },
]

const GenderStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const handleSelectPart = (type) => {
    trackFormEvent(GenderStep.displayName, type, stepNumber, 2)
    onSubmit({ gender: type })
  }

  return (
    <Form
      onSubmit={(val) => handleSelectPart(val)}
      render={({ handleSubmit, errors, values }) => (
        <form
          className='form-step step-gender'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <h4 className='title'>
            What is your gender?
          </h4>

          <div className='content'>
            <Field
              type='radio'
              name='gender'
              selected={info.gender}
            >
              {(props) => (
                <>
                  {properties.map((value) => (
                    <FinalFormRadio
                      {...props}
                      label={value.label}
                      autoFocus={true}
                      val={value.value}
                      onClick={() => {
                        handleSelectPart(value.value)
                      }}
                    />
                  ))}
                </>
              )}
            </Field>
          </div>
        </form>
      )}
    />
  )
}
GenderStep.displayName = 'What is your gender?'

export default GenderStep
