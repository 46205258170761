import React from 'react'
import { Form, Field } from 'react-final-form'
import FinalFormRadio from 'components/forms/FinalFormRadio'
import { trackFormEvent } from 'api/gtm'

const properties = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

const PrescriptionStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const handleSelectPart = (type) => {
    trackFormEvent(PrescriptionStep.displayName, type, stepNumber, 2)
    onSubmit({ prescription: type })
  }

  return (
    <Form
      onSubmit={(val) => handleSelectPart(val)}
      render={({ handleSubmit, errors, values }) => (
        <form
          className='form-step step-perscription'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <h4 className='title'>
            Do you have 5 or more prescriptions?
          </h4>

          <div className='content'>
            <Field
              type='radio'
              name='prescription'
              selected={info.perscription}
            >
              {(props) => (
                <>
                  {properties.map((value) => (
                    <FinalFormRadio
                      {...props}
                      label={value.label}
                      autoFocus={true}
                      val={value.value}
                      onClick={() => {
                        handleSelectPart(value.value)
                      }}
                    />
                  ))}
                </>
              )}
            </Field>
          </div>
        </form>
      )}
    />
  )
}
PrescriptionStep.displayName = 'Do you have 5 or more prescriptions?'

export default PrescriptionStep
