import React from 'react'

import Zipcode from '../components/Zipcode'

import styles from './FooterForm.module.scss'

const FooterForm = () => {
  return (
    <div className={styles.footerform}>
      <div className='container'>
        <div className='form-side'>
          <h3>Get affordable health coverage today!</h3>
          <p>Simply answer a few questions to get started.</p>
          <Zipcode />
        </div>
        <img
          src='/images/home/get-coverage.svg'
          alt='get coverage'
          width='406'
        />
      </div>
    </div>
  )
}

export default FooterForm
