import MainLayout from 'layout/MainLayout'
import Form5 from './Form5'
import React, { useEffect } from 'react'

import styles from './PersonalizedQuotePage.module.scss'

const PersonalizedQuotePage = () => {
  useEffect(() => {
    document.title = 'Compare Affordable Health Insurance Rates'
  }, [])
  return (
    <MainLayout
      title='Easily Compare Medicare Plans and Save Money'
      className={styles.personalizedQuote}
    >
      <Form5 />
    </MainLayout>
  )
}

export default PersonalizedQuotePage
