import React from 'react'
import { Form, Field } from 'react-final-form'
import FinalFormRadio from 'components/forms/FinalFormRadio'
import { trackFormEvent } from 'api/gtm'

const properties = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5 or more', value: 5 },
]

const MemberStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const handleSelectPart = (type) => {
    trackFormEvent(MemberStep.displayName, type, stepNumber, 2)
    onSubmit({ householdSize: type })
  }

  return (
    <Form
      onSubmit={(val) => handleSelectPart(val)}
      render={({ handleSubmit, errors, values }) => (
        <form
          className='form-step step-member'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <h4 className='title'>
            How many members are currently in your household?
          </h4>

          <div className='content'>
            <Field
              type='radio'
              name='member'
              selected={info.householdSize}
            >
              {(props) => (
                <>
                  {properties.map((value) => (
                    <FinalFormRadio
                      {...props}
                      label={value.label}
                      autoFocus={true}
                      val={value.value}
                      onClick={() => {
                        handleSelectPart(value.value)
                      }}
                    />
                  ))}
                </>
              )}
            </Field>
          </div>
        </form>
      )}
    />
  )
}
MemberStep.displayName = 'How many members are currently in your household?'

export default MemberStep
