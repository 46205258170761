import React from 'react'

import styles from './Manual.module.scss'

const Manual = () => {
  return (
    <div className={`${styles.manual} container`}>
      <div className='image'>
        <img
          src='/images/home/above-how-it-works.svg'
          alt='hands shaking'
          width='357'
        />
      </div>
      <div className='section-title'>How it works</div>
      <div className='description'>
        Our goal is to provide you with a great health care plan for your needs
        and budget. On top of that we strive to make this process as fast and
        simple as possible. Take a look below at how it works.
      </div>
      <div className='image-big'>
        <img
          src='/images/home/how-it-works.svg'
          alt='how it works'
          width='1200'
        />
      </div>
      <div className='three-cols'>
        <div className='item'>
          <div className='number'>
            <span>1</span>
          </div>
          <h4>Answer a few questions online</h4>
          <p>
            We collect some of your basic information online in order to quickly
            filter appropriate plans for your goal. This piece should not take
            any longer than 2 minutes.
          </p>
        </div>
        <div className='item'>
          <div className='number'>
            <span>2</span>
          </div>
          <h4>Complete a short phone interview</h4>
          <p>
            After you fill out the form a professional licensed insurance agent
            will give you a call within 15 minutes. They will go over a few more
            questions needed to provide rates.
          </p>
        </div>
        <div className='item'>
          <div className='number'>
            <span>3</span>
          </div>
          <h4>Choose the right plan!</h4>
          <p>
            Once we have verified your information, you will be able to choose
            the plan that meets your needs and receive a quote for health
            insurance.
          </p>
        </div>
      </div>
      <a href='/full-form.html' className='btn btn-blue-big'>
        Get Started
      </a>
    </div>
  )
}

export default Manual
