import React from 'react'
import { Form, Field } from 'react-final-form'
import FinalFormInput from 'components/forms/FinalFormInputNew'

import ArrowButton from 'components/ArrowButton'
import { required } from 'components/forms/validators'

import { trackFormEvent } from 'api/gtm'

const acceptOnlyAlphabetic = (value: string): string => {
  var regexs = /[^a-z ]*$/gim
  return value ? value.replace(regexs, '') : ''
}

const NameStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const nextStep = (values) => {

    trackFormEvent(
      NameStep.displayName,
      `${values.firstName},${values.lastName}`,
      stepNumber,
      2
    )

    onSubmit({
      firstName: values.firstName,
      lastName: values.lastName,
    })
  }

  const onNextClick = (errors: any, formData: any) => {
    // track event if form is invalid
    if (Object.keys(errors).length > 0) {
      trackFormEvent(
        NameStep.displayName,
        `${formData.firstName},${formData.lastName}`,
        stepNumber,
        2,
        'next',
        'error'
      )
    }
  }

  return (
    <Form
      onSubmit={(val) => nextStep(val)}
      render={({ handleSubmit, errors, values }) => (
        <form
          className='form-step step-name'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <h4 className='title'>A few more details about yourself</h4>

          <div className='content'>
            <div className='options'>
              <Field
                name='firstName'
                label='First name'
                placeholder='First name'
                className='firstname'
                defaultValue={info.first_name}
                validate={required}
              >
                {(props) => (
                  <FinalFormInput
                    pipe={acceptOnlyAlphabetic}
                    {...props}
                    autoFocus={true}
                  />
                )}
              </Field>

              <Field
                name='lastName'
                label='Last name'
                className='lastname'
                placeholder='Last name'
                defaultValue={info.last_name}
                validate={required}
              >
                {(props) => (
                  <FinalFormInput pipe={acceptOnlyAlphabetic} {...props} />
                )}
              </Field>
            </div>

            <ArrowButton
              type='submit'
              className='btn btn-blue'
              onClick={() => onNextClick(errors, values)}
            >
              Continue
            </ArrowButton>
          </div>
        </form>
      )}
    />
  )
}
NameStep.displayName = 'A few more details about yourself'

export default NameStep
