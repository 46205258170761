import { types } from './actions'

const initialState = {
  zipData: undefined
}

const project = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.GET_ZIPCODE_DATA: {
      state = { ...state, zipData: payload }
      break
    }

    default:
      break
  }
  return state
}

export default project