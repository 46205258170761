import React from 'react'
import { Form, Field } from 'react-final-form'
import FinalFormInput from 'components/forms/FinalFormInputNew'
import ArrowButton from 'components/ArrowButton'
import { emailValidator } from 'components/forms/validators'
import { trackFormEvent } from 'api/gtm'

const EmailStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const nextStep = (values) => {
    trackFormEvent(EmailStep.displayName, values.email, stepNumber, 2)

    onSubmit(values)
  }

  const onNextClick = (errors: any, formData: any) => {
    // track event if form is invalid
    if (Object.keys(errors).length > 0) {
      trackFormEvent(
        EmailStep.displayName,
        formData.email,
        stepNumber,
        2,
        'next',
        'error'
      )
    }
  }

  return (
    <Form
      onSubmit={(val) => nextStep(val)}
      render={({ handleSubmit, errors, values }) => (
        <form
          className='form-step step-email'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <h4 className='title'>Where should we email your results?</h4>
          <h5>Don’t worry, we won’t spam you.</h5>

          <div className='content'>
            <Field
              type='email'
              name='email'
              label='Email address'
              placeholder='Email address'
              defaultValue={info.email}
              validate={emailValidator}
            >
              {(props) => <FinalFormInput {...props} autoFocus={true} />}
            </Field>

            <ArrowButton
              type='submit'
              className='btn btn-blue'
              onClick={() => onNextClick(errors, values)}
            >
              Continue
            </ArrowButton>
          </div>
        </form>
      )}
    />
  )
}
EmailStep.displayName = 'Where should we email your results?'

export default EmailStep
