import React, { useEffect, useState } from 'react'
import MainLayout from 'layout/MainLayout'
import styles from './ConfirmationPage.module.scss'
import ConfirmItem from 'components/ConfirmItem'
import ProgressBar from 'components/ProgressBar'
import queryString from 'query-string'
import axios from 'axios'

import { getClicks } from 'api/penguin'
import Popup from './Popup'
import Spinner from 'components/Spinner'

const ConfirmationPage = (props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [leadData, setLeadData] = useState<JSON>()
  const [detail, setDetail] = useState<any>()
  const [progress, setProgress] = useState<number>(1)
  const [waitingForPost, setWaitingForPost] = useState<boolean>(true)
  const [isHeadline, setIsHeadline] = useState<boolean>(false)
  const [active, setActive] = useState<string>('hide')

  useEffect(() => {
    const getCurrentGeoInfo = async () => {
      let getUrl
      if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
        getUrl =
          document.location.protocol + '//' + process.env.REACT_APP_DOMAIN
      } else {
        getUrl = 'https://honestquote.com'
      }
      const response = await axios.get(getUrl)
      const { data } = await axios.get(
        'https://penguin.oapi.me/api/services/check-zip-us',
        {
          params: { zip: response.headers['x-optimize-geo-zipcode'] },
        }
      )
      if (data.valid === true) {
        getGeoInfo(data.zip)
      }
    }

    const getGeoInfo = async (zip) => {
      const { data } = await axios.get(
        'https://penguin.oapi.me/api/services/check-zip-us',
        {
          params: { zip: zip },
        }
      )
      if (data.valid === true) {
        setLeadData({
          zip_code: data.zip,
          city: data.city,
          state: data.state,
          first_name: '',
        })
      } else {
        getCurrentGeoInfo()
      }
    }

    document.title = 'Compare Affordable Home Insurance Rates'

    const params = JSON.parse(localStorage.getItem('param'))

    if (params !== null) {
      setLeadData(JSON.parse(localStorage.getItem('param')))
    } else if (params === null) {
      const { zipcode } = queryString.parse(window.location.search)
      if (zipcode !== undefined && zipcode.length === 5) {
        getGeoInfo(zipcode)
      } else {
        getCurrentGeoInfo()
      }
    }
  }, [])

  useEffect(() => {
    const getInsurancePolicies = async () => {
      let todayDate: any = new Date()

      todayDate.setFullYear(todayDate.getFullYear() - 65)
      todayDate.setMonth(todayDate.getMonth() - 6)

      const day = todayDate.getDate()
      const month = todayDate.getMonth() + 1
      const year = todayDate.getFullYear()
      try {
        const result = await getClicks(
          {
            zipCode: leadData?.zipcode,
            DOB: leadData?.dob || month + '/' + day + '/' + year,
          },
          4,
          3
        )
        setLoading(false)
        setDetail(result.data.data)
      } catch (err) {
        console.log(err)
      }
    }
    const waitingPost = async () => {
      const requestBody = { lead: leadData, optoId: leadData?.optoId }
      console.log(requestBody)
      try {
        const { data } = await axios.post(
          'https://submit.quickmedigap.com/medicare/post.php',
          requestBody
        )
        if (data.status === 'success') {
          setIsHeadline(true)
        } else {
          setIsHeadline(false)
        }
      } catch (err) {
        console.log(err)
        setIsHeadline(false)
      }
      setWaitingForPost(false)
      getInsurancePolicies()
      setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 1 : prev))
      }, 50)
    }
    if (leadData !== undefined) {
      if (leadData?.flowStatus !== 'Click') {
        waitingPost()
      } else {
        setWaitingForPost(false)
        getInsurancePolicies()
        setInterval(() => {
          setProgress((prev) => (prev < 100 ? prev + 1 : prev))
        }, 50)
      }
    }
  }, [leadData])

  useEffect(() => {
    if (!loading && progress >= 100)
    setActive('show')
  }, [progress]) //eslint-disable-line


  return leadData === undefined || waitingForPost ? (
    <Spinner />
  ) : (
    <MainLayout title='Confirmation' className={styles.confirmation}>
      <Popup status={active} />
      <header>
        <div className='container'>
          <img src={`/images/${process.env.REACT_APP_LOGO_URL}`} alt='logo' />
          <div className='contact-agent'>
            <span>Want to speak with an agent?</span>
            <span>877-587-3795</span>
            <span>Mon-Sun, 7am - 10pm CST</span>
          </div>
        </div>
      </header>
      <main>
        <ProgressBar
          total={100}
          current={progress}
          id={progress < 100 ? 'show' : 'hide'}
        />
        <h4>
          {loading || progress < 100
            ? `One moment, while we sort through all the best policies in ${leadData?.city}, ${leadData?.state}`
            : detail?.length === 0
            ? ''
            : isHeadline
            ? 'Thank you! We will be calling you within 5 minutes to confirm your details.'
            : `${
                leadData?.firstName || ''
              } click below to view quotes by our featured providers in ${
                leadData?.city
              }, ${leadData?.state}`}
        </h4>
        <div className='content'>
          {Array(loading || progress < 100 ? 4 : detail.length)
            .fill(0)
            .map((item, index) => (
              <ConfirmItem
                isLoading={loading || progress < 100}
                data={detail?.[index]}
              />
            ))}
          {detail && detail.length === 0 && progress > 99 ? (
            <span className='not-found'>
              Sorry, we couldn't find any providers
            </span>
          ) : (
            ''
          )}
        </div>
      </main>
    </MainLayout>
  )
}

export default ConfirmationPage
