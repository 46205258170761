import { NavLink, Link } from 'react-router-dom'
import styles from './Header.module.scss'
import { useState } from 'react'
const Header = () => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false)

  const toggleMenu = () => {
    setMenuOpened(!menuOpened)
  }

  return (
    <header className={styles.header}>
      <div className='container'>
        <Link to='/' className='logo'>
          <img src={`/images/${process.env.REACT_APP_LOGO_URL}`} alt='logo' />
        </Link>
        <ul className='main-menu'>
          <li>
            <NavLink to='/' activeClassName={styles.activeLink} exact>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to='/about-us' activeClassName={styles.activeLink}>
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to='/contact-us' activeClassName={styles.activeLink}>
              Contact Us
            </NavLink>
          </li>
        </ul>
        <nav
          className={`mobile-menu-wrap ${menuOpened && 'opened'}`}
          onClick={toggleMenu}
        >
          <span className='cls'></span>
          <span>
            <ul className='mobile-menu'>
              <li>
                <NavLink to='/' activeClassName={styles.activeLink} exact>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to='/about-us' activeClassName={styles.activeLink}>
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink to='/contact-us' activeClassName={styles.activeLink}>
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </span>
          <span className='cls'></span>
        </nav>
      </div>
    </header>
  )
}

export default Header
