import React from 'react'

import Zipcode from '../components/Zipcode'

import styles from './Hero.module.scss'

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className='container'>
        <h1>The Quickest Way to Get Affordable Health Coverage</h1>
        <p>Compare health insurance plans from providers.</p>
        <Zipcode />
      </div>
    </div>
  )
}

export default Hero
