import React from 'react'
import { Form, Field } from 'react-final-form'
import FinalFormInputMask from 'components/forms/FinalFormInputMaskNew'

import ArrowButton from 'components/ArrowButton'
import { dateValidator } from 'components/forms/validators'

import { trackFormEvent } from 'api/gtm'

const BirthdayStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const nextStep = (values) => {
    const arr = values.birth.split('/')

    trackFormEvent(
      BirthdayStep.displayName,
      `${values.birth}`,
      stepNumber,
      2
    )

    onSubmit({
      day: Number.parseInt(arr[1]),
      month: Number.parseInt(arr[0]),
      year: Number.parseInt(arr[2]),
      dob:
        Number.parseInt(arr[0]) +
        '/' +
        Number.parseInt(arr[1]) +
        '/' +
        Number.parseInt(arr[2]),
    })
  }

  const onNextClick = (errors: any, formData: any) => {
    // track event if form is invalid
    if (Object.keys(errors).length > 0) {
      trackFormEvent(
        BirthdayStep.displayName,
        `${formData.birth}`,
        stepNumber,
        2,
        'next',
        'error'
      )
    }
  }

  return (
    <Form
      onSubmit={(val) => nextStep(val)}
      render={({ handleSubmit, errors, values }) => (
        <form
          className='form-step step-birthday'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <h4 className='title'>When is your birthday?</h4>

          <div className='content'>
            <div className='options'>
              <Field
                type='tel'
                name='birth'
                label='Birthdate'
                placeholder='MM/DD/YYYY'
                defaultValue={
                  info.month
                    ? `${info.month}/${info.day}/${info.year}`
                    : undefined
                }
                validate={dateValidator}
              >
                {(props) => (
                  <FinalFormInputMask
                    {...props}
                    mask='99/99/9999'
                    maskPlaceholder=''
                  />
                )}
              </Field>
            </div>

            <ArrowButton
              type='submit'
              className='btn btn-blue'
              onClick={() => onNextClick(errors, values)}
            >
              Continue
            </ArrowButton>
          </div>
        </form>
      )}
    />
  )
}
BirthdayStep.displayName = 'When is your birthday?'

export default BirthdayStep
