import React, { useEffect } from 'react'
import ReactHelmet from 'react-helmet'
import Footer from './Footer'
import Header from './Header'
import styles from './SecondLayout.module.scss'
import { initialize } from 'utils/helper'
type Props = {
  title?: string
  className?: string
  pageType?: string
  children: React.ReactNode
}

const SecondLayout: React.FC<Props> = ({ title, className, pageType, children }) => {
  useEffect(() => {
    document.body.classList.add(styles.secondlayout)

    window['dataLayer'] = [
      {
        page_type: pageType,
        page_version: 1,
      }
    ]
    initialize()

    if (className) {
      document.body.classList.add(className)
    }

    // scroll to top
    window.scrollTo({ top: 0 })

    return () => {
      document.body.classList.remove(styles.secondlayout)
      if (className) {
        document.body.classList.remove(className)
      }
    }
  }, [className]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ReactHelmet>
        <title>{title} | {process.env.REACT_APP_DOMAINNAME} </title>
      </ReactHelmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default SecondLayout
