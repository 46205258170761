import React from 'react'
import { Form, Field } from 'react-final-form'
import FinalFormRadio from 'components/forms/FinalFormRadio'
import { trackFormEvent } from 'api/gtm'

const properties = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

const MedicarePartStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const handleSelectPart = async (type) => {
    trackFormEvent(MedicarePartStep.displayName, type, stepNumber, 2)
    onSubmit({ medicare_part: type })
  }

  return (
    <Form
      onSubmit={(val) => handleSelectPart(val)}
      render={({ handleSubmit, errors, values }) => (
        <form
          className='form-step step-medicarepart'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <h4 className='title'>
            Are you currently enrolled in Medicare Part A and Part B?
          </h4>

          <div className='content'>
            <Field
              type='radio'
              name='medicare_part'
              selected={info.medicare_part}
            >
              {(props) => (
                <>
                  {properties.map((value) => (
                    <FinalFormRadio
                      {...props}
                      label={value.label}
                      autoFocus={true}
                      val={value.value}
                      onClick={() => {
                        handleSelectPart(value.value)
                      }}
                    />
                  ))}
                </>
              )}
            </Field>
          </div>
        </form>
      )}
    />
  )
}
MedicarePartStep.displayName =
  'Are you currently enrolled in Medicare Part A and Part B?'

export default MedicarePartStep
