import React, { useEffect } from 'react'
import { Form, Field } from 'react-final-form'
import { trackFormEvent } from 'api/gtm'
import ArrowButton from 'components/ArrowButton'
import FinalFormInputMask from 'components/forms/FinalFormInputMaskNew'
import { phonenumberValidator } from 'components/forms/validators'
import { getStringfromObject } from 'utils/helper'

const PhoneNumberStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const nextStep = (values) => {
    trackFormEvent(
      PhoneNumberStep.displayName,
      getStringfromObject(values.phone),
      stepNumber,
      2
    )
    onSubmit(values)
  }

  const onNextClick = (errors: any, formData: any) => {
    // track event if form is invalid
    if (Object.keys(errors).length > 0) {
      trackFormEvent(
        PhoneNumberStep.displayName,
        getStringfromObject(formData),
        stepNumber,
        2,
        'next',
        'error'
      )
    }
  }

  // const [isEditDetail, setEditDetail] = useState(false)

  useEffect(() => {
    const doc = document.documentElement
    doc.style.setProperty('--address-form-gap', `70px`)
    ;(
      document.getElementsByName(
        'leadid_tcpa_disclosure'
      )[0] as HTMLInputElement
    ).value = document.getElementById('tcp_text')?.innerHTML
  }, []) //eslint-disable-line

  return (
    <div className='address-form-container step-phone'>
      <div className='form-wrapper'>
        <Form
          onSubmit={(val) => nextStep(val)}
          render={({ handleSubmit, submitting, errors, values }) => (
            <form
              className={`form-step form-flow`}
              onSubmit={handleSubmit}
              autoComplete='off'
            >
              <h4 className='title'>What is your phone number?</h4>

              <div className={`content`}>
                <Field
                  type='tel'
                  name='phone'
                  label='Phone number'
                  placeholder='Phone number'
                  defaultValue={info.phone}
                  validate={phonenumberValidator}
                >
                  {(props) => (
                    <FinalFormInputMask
                      {...props}
                      mask='(999) 999-9999'
                      maskPlaceholder=''
                      autoFocus={true}
                    />
                  )}
                </Field>
                <h5>
                  Valid phone number is required for an accurate quote
                  <br /> We{' '}
                  <u>
                    <strong>highly</strong>
                  </u>{' '}
                  respect your privacy
                </h5>

                <ArrowButton
                  type='submit'
                  className='btn btn-blue'
                  onClick={() => onNextClick(errors, values)}
                >
                  Continue
                </ArrowButton>
              </div>
            </form>
          )}
        />

        <input type='hidden' name='leadid_tcpa_disclosure' />
      </div>
    </div>
  )
}

PhoneNumberStep.displayName = 'What is your phone number?'

export default PhoneNumberStep
