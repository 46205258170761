import React from 'react'

import SecondLayout from 'layout/SecondLayout/SecondLayout'

import Hero from './sections/Hero'
import Plan from './sections/Plan'
import InsuranceType from './sections/InsuranceType'
import Manual from './sections/Manual'
import FAQ from './sections/FAQ'
import CompareRates from './sections/CompareRates'
import FooterForm from './sections/FooterForm'

import styles from './Home.module.scss'

const HomePage = () => (
  <SecondLayout
    pageType='homepage'
    title='Quick Healthcare - Home'
    className={styles.homepage}
  >
    <main>
      <Hero />
      <Plan />
      <InsuranceType />
      <Manual />
      <FAQ />
      <CompareRates />
      <FooterForm />
    </main>
  </SecondLayout>
)

export default HomePage
