import cookie from 'react-cookies'
import queryString from 'query-string'

export const getRequestData = (leadData: Object) => {
  const sessionIDCookie = cookie.load('_ga_RTM1G98C16')
  const clientIDCookie = cookie.load('_ga')

  let ga_client_id, ga_session_id
  if (sessionIDCookie) {
    const arr = sessionIDCookie.split('.')
    ga_session_id = arr[2]
  }
  if (clientIDCookie) {
    //clientIDCookie.splice(0,7)
    const arr = clientIDCookie.split('.')
    ga_client_id = arr[2] + '.' + arr[3]
  }

  const queryParams = queryString.parse(window.location.search)
  let curUrl = document.location.href
  let confirmUrl = '/home-insurance/confirmation.html'

  if (curUrl.indexOf('?') > 0) {
    curUrl = curUrl.substr(0, curUrl.indexOf('?'))
  }

  if (queryParams.confirm === '2') {
    confirmUrl = '/medicare-listings.html'
  }

  const requestBody = {
    lead: { ...leadData },
    publisherToken: queryParams.pub_token,
    publisherClickId: queryParams.pub_click_id,
    source: queryParams.source || queryParams.utm_source,
    adInfo: {
      ad_placement_id: queryParams.ad_placement_id,
      ad_placement_name: queryParams.ad_placement_name,
      ad_site_name: queryParams.ad_site_name,
      ad_site_id: queryParams.ad_site_id,
      ad_campaign_id: queryParams.cid,
      ad_group_id: queryParams.gid,
      ad_id: queryParams.aid,
      utm_source: queryParams.utm_source,
      utm_medium: queryParams.utm_medium,
      ga_client_id: ga_client_id,
      ga_session_id: ga_session_id,
      ad_click_id: queryParams.ad_click_id,
      voluum_click_id: queryParams.clickid,
    },
    tracking: {
      t1: queryParams.t1,
      t2: queryParams.t2,
      t3: queryParams.t3,
      t4: queryParams.t4,
      t5: queryParams.t5,
    },
    campaignId: 'yXukTs1e3-ZLHPSKFJRmB',
    submissionUrl: curUrl,
    confirmationUrl: 'https://' + document.domain + confirmUrl,
    jornayaLeadId: (
      document.getElementById('leadid_token') as HTMLInputElement
    )?.value,
    trustedFormCertUrl: (
      document.getElementsByName(
        'xxTrustedFormCertUrl',
      )[0] as HTMLInputElement
    )?.value,
    userAgent: navigator.userAgent,
    tcpaText: document.getElementById('tcp_text')?.innerText,
  }

  return requestBody
}