import React from 'react'
import { ReactComponent as ShieldSvg } from 'icons/shield.svg'
import './ConfirmItem.scss'
import ReactPlaceholder from 'react-placeholder'
import { ReactComponent as ArrowRightWhite } from 'icons/arrow-right-white.svg'
import { ReactComponent as LoadingSpinner } from 'icons/loading-spinner.svg'

const ConfirmItem = ({ isLoading, data }) => {
  return isLoading || data === undefined ? (
    <div className='loading'>
      <ShieldSvg />
      <div className='loading-placeholder'>
        <ReactPlaceholder
          ready={false}
          rows={2}
          type='text'
          showLoadingAnimation={true}
        ></ReactPlaceholder>
      </div>
      <LoadingSpinner />
    </div>
  ) : (
    <a className='loaded' href={data.url} target='_blank' rel='noreferrer'>
      <img src={data.imageUrl} alt='company_image' />
      <p className='description'>{data.headline}</p>
      <button className='view-my-quote'>
        <a href={data.url} target='_blank' rel='noreferrer'>
          <div className='view-quote'>
            View <span className='ismy'>My </span>Quote
          </div>
          <ArrowRightWhite />
        </a>
      </button>
    </a>
  )
}

export default ConfirmItem
