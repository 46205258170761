import InputMask, { Props as InputMaskProps } from 'react-input-mask'
import './FinalFormInputNew.scss'
import { ReactComponent as ValidationRequire } from 'icons/validation-require.svg'
import { ReactComponent as ZipcodeSvg } from 'icons/zipcode.svg'
import { useState, useEffect } from 'react'

type Props = {
  input: any
  meta: any
  label?: string
  id?: string
  value?: string
  placeholder?: string
  val?: string
}

const FinalFormInputMask: React.FC<Props & InputMaskProps> = ({
  input,
  meta,
  label,
  id,
  maskPlaceholder,
  className,
  val,
  ...props
}) => {
  const [fixV, setFixV] = useState<String>(val)

  const hasError = (meta.error || meta.submitError) && meta.touched
  const isValid = meta.valid && meta.touched
  const [focus, setFocus] = useState<Boolean>(false)
  const [myPlaceholder, setMyPlaceholder] = useState<Boolean>(false)
  const handleChange = (e) => {
    // meta = e.target.value
    if (e.target.value?.length > 0) {
      setFocus(true)
    } else {
      setFocus(false)
    }
    input.onChange(e.target.value)
    setFixV(e.target.value)
  }
  useEffect(() => {
    if (input.value?.length > 0) {
      setFocus(true)
    }
    // setFixV(input.value)
  }, []) //eslint-disable-line
  useEffect(() => {
    handleChange({ target: { value: val } })
  }, [val]) //eslint-disable-line
  return (
    <div
      className={`control-new ${label ? 'with-label' : ''} ${
        hasError ? 'has-error' : ''
      } ${isValid ? 'has-success' : ''} ${className} ${
        focus ? 'has-content' : 'has-no-content'
      }`}
      id={id}
    >
      <div className='zipcode-svg'>
        {label === 'Zipcode' || id === 'zipcode' ? <ZipcodeSvg /> : ''}
      </div>
      {label && <label style={{ pointerEvents: 'none' }}>{label}</label>}
      <InputMask
        {...input}
        {...props}
        maskChar={maskPlaceholder}
        onChange={(e) => handleChange(e)}
        value={fixV}
        onFocus={() => {
          setMyPlaceholder(true)
          input.onFocus()
        }}
        onBlur={() => {
          setMyPlaceholder(false)
          input.onBlur()
        }}
        placeholder={myPlaceholder ? props.placeholder : ''}
        // value={maskInfo}
      />
      <div className='validation-icon'>
        {hasError ? <ValidationRequire /> : <></>}
      </div>
      <span className='error-text'>
        {focus
          ? `Please enter a valid ${props.placeholder.toLowerCase()}`
          : 'This field is required'}
      </span>
    </div>
  )
}

export default FinalFormInputMask
