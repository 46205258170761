import React, { useState } from 'react'
import { Form, Field } from 'react-final-form'
import { Link } from 'react-router-dom'
import { trackEvent, trackFormEvent } from 'api/gtm'
import FinalFormInput from 'components/forms/FinalFormInputNew'
import ArrowButton from 'components/ArrowButton'
import { required, zipcode } from 'components/forms/validators'
import FinalFormInputMask from 'components/forms/FinalFormInputMaskNew'

import { states } from 'data/usStates'
import { getStringfromObject } from 'utils/helper'

const AddressStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const [isEditDetail, setIsEditDetail] = useState<boolean>(false)

  const nextStep = async (values) => {
    trackFormEvent(
      AddressStep.displayName,
      getStringfromObject(values),
      stepNumber,
      2
    )

    trackEvent({
      event: 'lead_submit',
      form_version: 2,
      user_first_name: info.firstName,
      user_last_name: info.lastName,
      user_email_address: info.email,
      user_phone_number: info.phone,
    })

    await onSubmit({address: values.address, city: values.city, state: values.state, zipcode: values.zipcode})
  }

  const onNextClick = (errors: any, formData: any) => {
    // track event if form is invalid
    if (Object.keys(errors).length > 0) {
      trackFormEvent(
        AddressStep.displayName,
        getStringfromObject(formData),
        stepNumber,
        2,
        'next',
        'error'
      )
    }
  }
  return (
    <div className='address-form-container step-new-address'>
      <div className='form-wrapper'>
        <Form
          onSubmit={(val) => nextStep(val)}
          render={({ handleSubmit, submitting, errors, values }) => (
            <form
              className={`form-step form-flow`}
              onSubmit={handleSubmit}
              autoComplete='off'
            >
              <h4 className='title'>
                {info.firstName}, last step and your results are ready!
              </h4>

              <div className={`content`}>
                <div className='options'>
                  <Field
                    name='address'
                    id='address'
                    label='Street address'
                    placeholder='Street address'
                    defaultValue={info.address}
                    validate={required}
                  >
                    {(props) => <FinalFormInput {...props} />}
                  </Field>
                  { isEditDetail === false ? <span className={'non-detail'} onClick={() => setIsEditDetail(true)}>
                    {info.city + ', ' + info.state + ' ' + info.zipcode}</span> : <div className='detailedAddress'>
                  <Field
                    type='text'
                    name='city'
                    label='City'
                    placeholder='City'
                    id='city'
                    defaultValue={info.city}
                    validate={required}
                  >
                    {(props) => <FinalFormInput {...props} val={info.city} />}
                  </Field>

                  <Field
                    type='select'
                    component='select'
                    name='state'
                    id='state'
                    placeholder='State'
                    label='State'
                    options={Object.values(states)}
                    defaultValue={info.state}
                  >
                    {(props) => (
                      <FinalFormInput
                        {...props}
                        component='select'
                        val={info.state}
                      />
                    )}
                  </Field>
                  <Field
                    type='tel'
                    name='zipcode'
                    label='Zip code'
                    placeholder='Zip code'
                    defaultValue={info.zipcode}
                    validate={zipcode}
                    id='zipcode'
                  >
                    {(props) => (
                      <FinalFormInputMask
                        mask='99999'
                        maskPlaceholder=''
                        val={info.zipcode}
                        {...props}
                      />
                    )}
                  </Field>
                  </div>}
                </div>

                <ArrowButton
                  type='submit'
                  className='btn btn-blue'
                  onClick={() => onNextClick(errors, values)}
                >
                  Continue
                </ArrowButton>
              </div>
            </form>
          )}
        />

        <input type='hidden' name='leadid_tcpa_disclosure' />
        <label
          className='tcp-text mx-auto'
          id='tcp_text'
          htmlFor='leadid_tcpa_disclosure'
        >
          By clicking “Continue” above, I provide my electronic
          signature and express written consent to be contacted for marketing
          purposes at the email and phone number provided above by up to five{' '}
          <Link to='/partners.html' target='_blank'>
            Marketing Partners
          </Link>{' '}
          via phone calls (including wireless numbers), text messages SMS/MMS,
          emails, postal mail regarding health insurance products and services,
          including Medicare Advantage, Part D, Medicare Supplement insurance
          plans and any service and product requested within this application. I
          further expressly consent to be contacted by the means provided above,
          even if it is a wireless number, regardless of whether I am on any
          Federal or State DNC (“Do Not Call”) and/or DNE (“Do Not Email”) list
          or registry and I agree that such contact may be made using an
          automatic telephone dialing system and/or artificial or prerecorded
          voice calls and messages. I understand and acknowledge that my consent
          is not a condition of purchasing any property, goods, or services and
          that I may revoke my consent at any time. I understand and acknowledge
          that data and message rates may apply and I am over 18 years of age.
          Insurance companies, agents or representatives that receive my
          information from this website or its partner companies, may confirm my
          information through the use of a consumer report. Agents are not
          connected with or endorsed by the U.S. government or the federal
          Medicare program. One of these parties may handle your health
          insurance case. Optimze.Ad complies with applicable Federal civil
          rights laws and does not discriminate on the basis of race, color,
          national origin, age, disability, or sex.
          <br />
          <br /> Additionally, by clicking “Continue”, I acknowledge that
          I have read, understand, and agree to this website’s{' '}
          <Link to='/privacy-policy' target='_blank'>
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link to='/terms-of-use' target='_blank'>
            Terms of Use
          </Link>{' '}
          and am directing them to share my information to providers within the
          websites network for the purposes of providing me information about
          the services and products requested within this application.
        </label>
      </div>
    </div>
  )
}

AddressStep.displayName = 'Last Step'

export default AddressStep
