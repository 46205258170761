import React, { useEffect, useState } from 'react'
import './FinalFormInputNew.scss'

import { ReactComponent as ValidationRequire } from 'icons/validation-require.svg'

type Props = {
  component?: 'input' | 'textarea' | 'select'
  input: any
  meta: any
  label?: string
  id?: string
  val?: string
  options?: any
  pipe?: (value: string) => string
}

const FinalFormInput: React.FC<
  Props & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  component = 'input',
  input,
  meta,
  label,
  pipe,
  options,
  id,
  val,
  placeholder,
  ...props
}) => {
  const [fixV, setFixV] = useState<String>('')

  const hasError = (meta.error || meta.submitError) && meta.touched
  const isValid = meta.valid && meta.touched

  const [focus, setFocus] = useState<Boolean>(false)
  const [myPlaceholder, setMyPlaceholder] = useState<Boolean>(false)

  const handleChange = (e) => {
    input.onChange(pipe ? pipe(e.target.value) : e.target.value)
    if (e.target.value?.length > 0) {
      setFocus(true)
    } else {
      setFocus(false)
    }
    setFixV(e.target.value)
  }

  const inputElem = React.createElement(component, {
    ...input,
    ...props,
    placeholder: myPlaceholder ? placeholder : '',
    value: fixV,
    onChange: handleChange,
    onFocus: function () {
      setMyPlaceholder(true)
      input.onFocus()
    },
    onBlur: function () {
      setMyPlaceholder(false)
      input.onBlur()
    },
  })

  useEffect(() => {
    if (input.value?.length > 0 || input.type === 'radio') {
      setFocus(true)
    }
    // setFixV(input.value)
    // console.log(input.type)
  }, []) //eslint-disable-line

  useEffect(() => {
    handleChange({ target: { value: val } })
  }, [val]) //eslint-disable-line

  return (
    <div
      className={`control-new ${label ? 'with-label' : ''} ${
        hasError ? 'has-error' : ''
      } ${isValid ? 'has-success' : ''} ${
        focus ? 'has-content' : 'has-no-content'
      }`}
      id={id}
    >
      {label && <label style={{ pointerEvents: 'none' }}>{label}</label>}
      {component !== 'select' && inputElem}
      {component !== 'select' && (
        <div className='validation-icon'>
          {hasError ? <ValidationRequire /> : <></>}
        </div>
      )}
      {component === 'select' && (
        <select {...input} {...props} onChange={handleChange}>
          {options?.map((value) => {
            return (
              <option key={value} value={value}>
                {value}
              </option>
            )
          })}
        </select>
      )}
      <span className='error-text'>
        {focus
          ? `Please enter a valid ${placeholder.toLowerCase()}`
          : 'This field is required'}
      </span>
    </div>
  )
}

export default FinalFormInput
