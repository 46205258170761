import React from 'react'

import styles from './Plan.module.scss'

const Plan = () => {
  return (
    <div className={styles.plan}>
      <div className='tooltips'></div>
      <h2 className='section-title container'>
        Finding the right plan should be simple
      </h2>
      <div className='right-plan container'>
        <div className='right-plan-text'>
          <div className='item'>
            <h3>Understand</h3>
            <p>
              Your licensed insurance agent can answer all of your Medicare
              questions and look for opportunities to save.
            </p>
          </div>
          <div className='item'>
            <h3>Compare</h3>
            <p>
              Your licensed insurance agent will help match you to the carriers
              with the optimal coverage for your case.
            </p>
          </div>
          <div className='item'>
            <h3>Save</h3>
            <p>
              By reviewing your options and making a change, you can save both
              time and money.
            </p>
          </div>
        </div>
        <div className='right-plan-quotes'>
          <div className='title'>Sample Quotes</div>
          <img src='/images/home/plan/plan-card-1.png' alt='card' />
          {' - '}
          <img src='/images/home/plan/plan-card-2.png' alt='card' />
          {' - '}
          <img src='/images/home/plan/plan-card-3.png' alt='card' />
        </div>
      </div>
    </div>
  )
}

export default Plan
