import React from 'react'
import { Form, Field } from 'react-final-form'
import FinalFormRadio from 'components/forms/FinalFormRadio'
import { trackFormEvent } from 'api/gtm'

let properties = [
  { label: 'Under $45,000', value: '0-45000' },
  { label: '$45,000 to $65,000', value: '45000-65000' },
  { label: '$65,000 to $85,000', value: '65000-85000' },
  { label: 'Over $85,000', value: '85000' },
]

const AnnualIncomeStep: React.FC<StepComponentProps> = ({
  info,
  onSubmit,
  stepNumber,
}) => {
  const handleSelectPart = (type) => {
    trackFormEvent(AnnualIncomeStep.displayName, type, stepNumber, 2)
    onSubmit({ income: type })
  }

  return (
    <Form
      onSubmit={(val) => handleSelectPart(val)}
      render={({ handleSubmit, errors, values }) => (
        <form
          className='form-step step-annualincome'
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <h4 className='title'>
            What is your current annual income?
          </h4>

          <div className='content'>
            <Field
              type='radio'
              name='income'
              selected={info.income}
            >
              {(props) => (
                <>
                  {properties.map((value) => (
                    <FinalFormRadio
                      {...props}
                      label={value.label}
                      autoFocus={true}
                      val={value.value}
                      onClick={() => {
                        handleSelectPart(value.value)
                      }}
                    />
                  ))}
                </>
              )}
            </Field>
          </div>
        </form>
      )}
    />
  )
}
AnnualIncomeStep.displayName = 'What is your current annual income?'

export default AnnualIncomeStep
