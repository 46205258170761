import ZipCodeStep from './ZipCodeStep'
import GenderStep from './GenderStep'
import BirthdayStep from './BirthdayStep'
import MedicarePartStep from './MedicarePartStep'
import PrescriptionStep from './PrescriptionStep'
import AnnualIncomeStep from './AnnualIncomeStep'
import MemberStep from './MemberStep'
import NameStep from './NameStep'
import EmailStep from './EmailStep'
import PhoneNumberStep from './PhoneNumberStep'
import AddressStep from './AddressStep'

const components: Array = [
  AddressStep,
  PhoneNumberStep,
  EmailStep,
  NameStep,
  MemberStep,
  AnnualIncomeStep,
  PrescriptionStep,
  MedicarePartStep,
  BirthdayStep,
  GenderStep,
  ZipCodeStep,
].reverse()

export default components
