export const insertLeadScripts = () => {
  document.getElementById('LeadiDscript_campaign')?.remove()
  ;(function () {
    // remove old scripts
    let s = document.createElement('script')
    s.id = 'LeadiDscript_campaign'
    s.type = 'text/javascript'
    s.async = true
    s.src =
      '//create.lidstatic.com/campaign/1a72ea9a-e602-297f-f916-02c9c37134bf.js?snippet_version=2'
    let LeadiDscript = document.getElementById('LeadiDscript')
    LeadiDscript.parentNode.insertBefore(s, LeadiDscript)
  })()
}

export const insertTrustFormScripts = () => {
  document.getElementById('xxTrustedForm_script')?.remove()
  // trustform scripts
  ;(function () {
    let field = 'xxTrustedFormCertUrl'
    let provideReferrer: any = false
    let invertFieldSensitivity = false
    let tf = document.createElement('script')
    tf.id = 'xxTrustedForm_script'
    tf.type = 'text/javascript'
    tf.async = true
    tf.src =
      'http' +
      ('https:' === document.location.protocol ? 's' : '') +
      '://api.trustedform.com/trustedform.js?provide_referrer=' +
      escape(provideReferrer) +
      '&field=' +
      escape(field) +
      '&l=' +
      new Date().getTime() +
      Math.random() +
      '&invert_field_sensitivity=' +
      invertFieldSensitivity
    let s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(tf, s)
  })()
}
